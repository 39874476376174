import type { FC, ReactNode } from 'react';
import { Typography } from 'ui/Typography';

import styles from './List.module.scss';

interface ListProps {
  header: ReactNode;
  children?: ReactNode;
}

export const List: FC<ListProps> = ({ header, children }) => {
  return (
    <div className={styles.container}>
      <Typography variant="body" size="large" color="primary" bold>
        {header}
      </Typography>
      <div className={styles.list}>{children}</div>
    </div>
  );
};

/**
 * Replace all occurrences of lowercase norwegian and swedish diacritics
 * (accented characters) with basic latin unicode characters.
 */
export const replaceDiacritics = (inputString: string): string => {
  return inputString
    .replace(/æ/g, 'ae')
    .replace(/ø/g, 'oe')
    .replace(/å/g, 'aa')
    .replace(/é|ê|è/g, 'e')
    .replace(/û/g, 'u')
    .replace(/î/g, 'i')
    .replace(/ô/g, 'o')
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'o');
};

export const slugify = (inputString: string): string => {
  const lowerCase = inputString.trim().toLowerCase();

  return replaceDiacritics(lowerCase)
    .replace(/ /g, '-') // replace spaces with '-'
    .replace(/[^a-z0-9-]/g, '-') // replace all remaining unsupported characters with a '-
    .replace(/-+/g, '-') // remove multiple '-' characters
    .replace(/^-/, '') // remove '-' if it's the first character
    .replace(/-$/, ''); // remove '-' if it's the last character
};

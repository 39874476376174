import type { MouseEventHandler, VFC } from 'react';
import { useCallback } from 'react';
import type { CnpAuthor } from '@vgtv/api-client/lib/cnp_authors';

import styles from './Byline.module.scss';

interface BylineProps {
  author: CnpAuthor;
  onClick?: () => void;
}

export const Byline: VFC<BylineProps> = ({ author, onClick }) => {
  const getAssetBylines = (author: CnpAuthor) => {
    const { contacts, title } = author;
    const email = contacts?.find((contact) => contact.type === 'email')?.value;

    return {
      title,
      email,
    };
  };

  const { title, email } = getAssetBylines(author);
  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.stopPropagation();

      onClick?.();
    },
    [onClick],
  );

  return (
    <a
      href={email ? `mailto:${email}` : undefined}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.byline}
      onClick={handleClick}
    >
      {title}
    </a>
  );
};

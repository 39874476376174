import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { GoBackButton } from '../GoBackButton/GoBackButton';
import { List } from '../List/List';
import { AssetDetails } from '../AssetDetails/AssetDetails';
import { usePlayer } from '../../../hooks/usePlayer';
import { setRef } from '../../../utils/reactRef';
import type { OverlayProps } from '../../Overlay/Overlay';
import { DisplayAd } from '../../DisplayAd/DisplayAd';

import styles from './Overlay.module.scss';

export const Overlay: FC<OverlayProps> = ({ assetId, vendor, playerRef, onAssetReady }) => {
  const [asset, setAsset] = useState<SvpAssetAdditional>();
  const [isPlaybackReady, setIsPlaybackReady] = useState(false);

  const handleAssetReady = useCallback(
    (asset: SvpAssetAdditional) => {
      onAssetReady?.(asset);
      setAsset(asset);
    },
    [onAssetReady],
  );

  const { player, playerNodeId } = usePlayer({
    assetId,
    vendor,
    onAssetReady: handleAssetReady,
    onPlaybackReadyChange: setIsPlaybackReady,
  });

  useEffect(() => {
    if (playerRef && player) {
      setRef(playerRef, player);
    }
  }, [player]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.primary}>
          <DisplayAd asset={asset} variant="desktop" />
          <div className={styles.player}>
            <div id={playerNodeId} />
          </div>
          {asset && <AssetDetails asset={asset} />}
        </div>
        <div className={styles.secondary}>
          <List asset={isPlaybackReady ? asset : undefined} />
        </div>
      </div>
      <div className={styles.back}>
        <GoBackButton />
      </div>
    </div>
  );
};

import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { loadTag } from '../../services/xandr/xandr';
import { config } from '../../config';
import { getKeywords } from '../../services/xandr/helpers';

import styles from './DisplayAd.module.scss';

interface DisplayAdProps {
  asset?: SvpAssetAdditional;
  variant: 'desktop' | 'mobile';
}

const targetId = 'companion-ad';

export const DisplayAd: FC<DisplayAdProps> = ({ asset, variant }) => {
  const ref = useRef<HTMLDivElement>(null!);
  const adRef = useRef<HTMLDivElement>(null!);
  const [isAdLoaded, setIsAdLoaded] = useState(false);

  const placement = config.ads.placements[variant].find((placement) => placement.targetId === targetId);

  useEffect(() => {
    if (asset && placement) {
      if (placement) {
        loadTag({
          ...placement,
          keywords: {
            ...placement.keywords,
            ...getKeywords(asset),
          },
        }).then(setIsAdLoaded);
      }
    }
  }, [asset, placement]);

  useEffect(() => {
    if (isAdLoaded && placement) {
      const [width] = placement.sizes;

      adRef.current.style.transform = `scale(${ref.current.clientWidth / width})`;
    }
  }, [isAdLoaded, placement]);

  if (!placement) {
    return null;
  }

  const [width, height] = placement.sizes;

  return (
    <div
      className={styles.container}
      ref={ref}
      style={{
        display: isAdLoaded ? 'block' : 'none',
        aspectRatio: `${width} / ${height}`,
        maxWidth: width,
      }}
    >
      <div id={targetId} className={styles.ad} ref={adRef} />
    </div>
  );
};

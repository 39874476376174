import type { SvpAssetAdditional, SvpAssetId } from '@vgtv/api-client/lib/svp_asset';
import { useEffect, useState } from 'react';
import { isAssetRestricted } from 'asset-helpers';
import { useAbortController } from 'ui/useAbortController';

import { relatedApi } from '../services';
import { useAssetCache } from '../components/AssetCache/AssetCache';
import type { Vendor } from '../types';

export const RELATED_ASSETS_LIMIT = 10;

const defaultAssetsState: SvpAssetAdditional[] = [];

export const useRelatedAssets = ({ assetId, vendor }: { assetId?: SvpAssetId; vendor?: Vendor }) => {
  const [assets, setAssets] = useState<SvpAssetAdditional[]>(defaultAssetsState);

  const { assetCache } = useAssetCache();
  const { getSignal } = useAbortController();

  useEffect(() => {
    setAssets(defaultAssetsState);

    if (!assetId || !vendor) {
      return;
    }

    (async () => {
      try {
        const relatedAssets = await relatedApi.getRelatedAssets(
          {
            assetId,
            limit: RELATED_ASSETS_LIMIT,
            vendor,
          },
          { signal: getSignal() },
        );

        const assets = relatedAssets.filter((asset) => !isAssetRestricted(asset));

        assets.forEach((asset) => {
          assetCache.setItem(`${asset.provider}:${asset.id}`, asset);
        });

        setAssets(assets);
      } catch (err) {
        if (err instanceof Error && err.name === 'AbortError') {
          return;
        }

        console.error(err);
      }
    })();
  }, [assetId, vendor, getSignal, assetCache]);

  return { assets };
};

// ../../node_modules/@vgtv/api-client/lib/chunk-IUHVRWTE.js
var Provider = /* @__PURE__ */ ((Provider2) => {
  Provider2["VGTV"] = "vgtv";
  Provider2["AB"] = "ab";
  return Provider2;
})(Provider || {});

// src/index.ts
var VGTVCategory = {
  NEWS: 1,
  SPORT: 3,
  FOOTBALL: 52,
  ENTERTAINMENT: 100324,
  SPOTLIGHT: 5,
  VIRAL: 279,
  DOCUMENTARY: 121
};
var isDocumentary = (asset) => {
  const { category } = asset;
  return category.id === VGTVCategory.DOCUMENTARY || category.parentId === VGTVCategory.DOCUMENTARY;
};
var isEntertainment = (asset) => {
  const { category } = asset;
  return category.id === VGTVCategory.ENTERTAINMENT || category.parentId === VGTVCategory.ENTERTAINMENT;
};
var isEpisode = (asset) => {
  var _a;
  return ((_a = asset.series) == null ? void 0 : _a.episodeNumber) !== void 0;
};
var getAssetAccessLevel = (asset) => {
  var _a, _b;
  const access = (_b = (_a = asset == null ? void 0 : asset.additional) == null ? void 0 : _a.access) != null ? _b : {};
  return Object.keys(access).length > 0 ? Object.keys(access)[0] : void 0;
};
var getAssetDisplayTitle = (asset) => {
  return asset.title.replace(new RegExp(`^${asset.category.title}: `, "i"), "").replace(new RegExp("^VG Nyheter: ", "i"), "").replace(/^DIREKTE: /, "").replace(/^DIREKTE (kl([.]|) |)([0-9]{2}\.[0-9]{2}): /, "").replace(/\([0-9]{2}[.:][0-9]{2}\)$/, "");
};
var getAssetMainImage = (asset) => {
  return asset.images.main;
};
var getAssetDisplays = (asset) => {
  if (isAssetUpcoming(asset) || isLiveStream(asset) || isDocumentary(asset) || isEpisode(asset)) {
    return void 0;
  }
  return asset.displays;
};
var getAssetTeaserDescription = (asset) => {
  const { category } = asset;
  if (isDocumentary(asset) || category.isSeries) {
    return asset.description;
  }
  return void 0;
};
var subscriptionLabelMapping = {
  [Provider.VGTV]: {
    plus: "VG+",
    sport: "VG+ Sport"
  },
  [Provider.AB]: {
    plus: "Plus"
  }
};
var getSubscriptionLabel = (asset) => {
  const access = getAssetAccessLevel(asset);
  if (!access) {
    return void 0;
  }
  return subscriptionLabelMapping[asset.provider][access];
};
var isAssetPaywalled = (asset) => {
  return getAssetAccessLevel(asset) !== void 0;
};
var isLiveStream = (asset) => {
  return asset.streamType === "live";
};
var isWasLiveStream = (asset) => {
  return asset.streamType === "wasLive";
};
var getLiveAssetFlightTimesStart = (asset) => {
  if (!isLiveStream(asset)) {
    return void 0;
  }
  const { flightTimes: { start } = {}, additional: { metadata: { eventStartTime, studioStartTime } = {} } = {} } = asset;
  if (studioStartTime) {
    return parseInt(studioStartTime, 10);
  }
  if (eventStartTime) {
    return parseInt(eventStartTime, 10);
  }
  return start;
};
var getAssetFlightTimesStart = (asset) => {
  const { flightTimes: { start } = {} } = asset;
  return start;
};
var getAssetPublishedDate = (asset) => {
  if (isLiveStream(asset) || isAssetUpcoming(asset) || isSponsoredAsset(asset)) {
    return void 0;
  }
  if (isWasLiveStream(asset)) {
    return getAssetFlightTimesStart(asset);
  }
  return asset.published;
};
var isUpcomingLive = (asset, relativeTo) => {
  const start = getLiveAssetFlightTimesStart(asset);
  if (!start) {
    return false;
  }
  const nowUnixTimestamp = relativeTo || Date.now() / 1e3;
  return nowUnixTimestamp < start;
};
var isAssetUpcoming = (asset, relativeTo) => {
  const start = getAssetFlightTimesStart(asset);
  if (!start) {
    return false;
  }
  const nowUnixTimestamp = relativeTo || Date.now() / 1e3;
  return nowUnixTimestamp < start;
};
var isCurrentlyLive = (asset, relativeTo) => {
  const start = getLiveAssetFlightTimesStart(asset);
  if (!start) {
    return false;
  }
  const nowUnixTimestamp = relativeTo || Date.now() / 1e3;
  return nowUnixTimestamp >= start;
};
var assetHasTag = (asset, tagId) => {
  var _a, _b;
  return (_b = (_a = asset.additional) == null ? void 0 : _a.tags) == null ? void 0 : _b.includes(tagId);
};
var assetHasStory = (asset, storyId) => {
  var _a, _b;
  return (_b = (_a = asset.additional) == null ? void 0 : _a.stories) == null ? void 0 : _b.includes(storyId);
};
var isVerticalAsset = (asset) => {
  return asset.additional.metadata.aspectRatio === "0.56";
};
var isAssetActive = (asset) => {
  return asset.status === "active";
};
var isMock = (asset) => {
  return asset.streamType === "mock";
};
var emptyAssetMetadata = {};
Object.freeze(emptyAssetMetadata);
var getAssetMetadata = (asset) => {
  var _a;
  return ((_a = asset.additional) == null ? void 0 : _a.metadata) || emptyAssetMetadata;
};
var getSeoAssetTitle = (asset) => {
  return (getAssetMetadata(asset).seoTitle || "").trim() || asset.title;
};
var getSeoAssetDescription = (asset) => {
  return (getAssetMetadata(asset).seoDescription || "").trim() || asset.description;
};
var getAssetByline = (asset) => {
  const { bylineName, bylineEmail } = getAssetMetadata(asset);
  if (bylineName && bylineEmail) {
    return {
      name: bylineName.trim(),
      email: bylineEmail.trim()
    };
  }
  return void 0;
};
var ALLOWED_CATEGORIES_BRANDSTUDIO = [212];
var isCategoryRestricted = (vendor, category) => {
  if (vendor === "brandstudio") {
    return !ALLOWED_CATEGORIES_BRANDSTUDIO.includes(category.parentId);
  }
  return !category.showCategory;
};
var isAssetRestricted = (asset) => {
  return isCategoryRestricted(asset.provider, asset.category);
};
var isSponsoredAsset = (asset) => {
  return asset.provider === "brandstudio";
};
var getAssetEmbeddedTags = (asset) => {
  return asset.additional.tags.map((tagId) => asset._embedded.tags.find(({ id }) => tagId === id)).filter((tag) => Boolean(tag));
};
var RESTRICTED_TAGS = {
  vgtv: [
    "a9934b2f-9328-4bbc-a620-0d1e81fe0b66",
    // NENT
    "1edb9ec7-7902-4ff1-a8f8-7f61d8e5bac9",
    // VG: Mangfold
    "4af46dc8-721e-4e79-884b-1818af5d8a09",
    // vg:Ung-journalistikk
    "57eff209-28df-4a1a-bf47-17c2fe4244c5"
    // Stories
  ]
};
var getAssetDisplayTags = (asset) => {
  var _a;
  const restrictedTags = (_a = RESTRICTED_TAGS[asset.provider]) != null ? _a : [];
  return getAssetEmbeddedTags(asset).filter((tag) => !restrictedTags.includes(tag.id));
};
var getAssetTags = (asset) => {
  return getAssetEmbeddedTags(asset).map((tag) => tag.tag);
};
var getAssetPreviewUrl = (asset) => {
  const { preview_vivi_category } = getAssetMetadata(asset);
  return preview_vivi_category;
};
var isAssetGeoblocked = (asset) => {
  return asset.streamConfiguration.properties.includes("geoblocked");
};
var isAssetEncrypted = (asset) => {
  return asset.streamConfiguration.properties.includes("encrypted");
};
var isAssetTokenSecured = (asset) => {
  return asset.streamConfiguration.properties.includes("tokenSecured");
};
export {
  assetHasStory,
  assetHasTag,
  getAssetAccessLevel,
  getAssetByline,
  getAssetDisplayTags,
  getAssetDisplayTitle,
  getAssetDisplays,
  getAssetEmbeddedTags,
  getAssetFlightTimesStart,
  getAssetMainImage,
  getAssetMetadata,
  getAssetPreviewUrl,
  getAssetPublishedDate,
  getAssetTags,
  getAssetTeaserDescription,
  getLiveAssetFlightTimesStart,
  getSeoAssetDescription,
  getSeoAssetTitle,
  getSubscriptionLabel,
  isAssetActive,
  isAssetEncrypted,
  isAssetGeoblocked,
  isAssetPaywalled,
  isAssetRestricted,
  isAssetTokenSecured,
  isAssetUpcoming,
  isCategoryRestricted,
  isCurrentlyLive,
  isDocumentary,
  isEntertainment,
  isEpisode,
  isLiveStream,
  isMock,
  isSponsoredAsset,
  isUpcomingLive,
  isVerticalAsset,
  isWasLiveStream
};

import type { FC, MutableRefObject } from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import type { SvpAssetAdditional, SvpAssetId } from '@vgtv/api-client/lib/svp_asset';
import type { Player as SvpPlayer } from '@schibsted-svp/web-player';

import { Player } from '../Player/Player';
import { AssetDetails } from '../AssetDetails/AssetDetails';
import { AssetList } from '../AssetList/AssetList';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import type { Vendor } from '../../types';

import styles from './Overlay.module.scss';

export interface OverlayProps {
  assetId?: SvpAssetId;
  vendor?: Vendor;
  playerRef?: MutableRefObject<SvpPlayer | null>;
  onAssetReady?: (asset: SvpAssetAdditional) => void;
}

export const Overlay: FC<OverlayProps> = ({ assetId, vendor, playerRef, onAssetReady }) => {
  const containerRef = useRef<HTMLDivElement>(null!);
  const [isPlaybackReady, setIsPlaybackReady] = useState(false);
  const [asset, setAsset] = useState<SvpAssetAdditional>();

  const handleAssetReady = useCallback(
    (asset: SvpAssetAdditional) => {
      onAssetReady?.(asset);
      setAsset(asset);
    },
    [onAssetReady],
  );

  const handlePlaybackReadyChange = useCallback((newIsPlaybackReady) => {
    setIsPlaybackReady(newIsPlaybackReady);

    if (!newIsPlaybackReady) {
      containerRef.current.scroll({ top: 0 });
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    const listener = (e: HTMLElementEventMap['scroll']) => {
      const { scrollTop } = e.target as HTMLDivElement;

      container.style.setProperty('--overlay-scroll-top', `${scrollTop}px`);
    };

    container.addEventListener('scroll', listener, { passive: true });

    return () => {
      container.removeEventListener('scroll', listener);
    };
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <Player
        assetId={assetId}
        onAssetReady={handleAssetReady}
        onPlaybackReadyChange={handlePlaybackReadyChange}
        scrollTop="var(--overlay-scroll-top, 0px)"
        ref={playerRef}
        vendor={vendor}
      />
      <div className={styles.content}>
        {asset && (
          <ErrorBoundary fallback={null}>
            <AssetDetails asset={asset} />
            <AssetList asset={isPlaybackReady ? asset : undefined} />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

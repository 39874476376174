import type { SvpAssetId } from '@vgtv/api-client/lib/svp_asset';

import type { AssetStorage } from '../components/AssetCache/AssetCache';
import { svpApi } from '../services';

const getVideoElements = () => {
  const elements = [...document.querySelectorAll<HTMLAnchorElement>('.article-extract a')];

  return elements.filter((element) => element.href.includes('/video/'));
};

const getAssetIds = (videoElements: HTMLAnchorElement[], callback: (element: HTMLAnchorElement) => string) => {
  return [
    ...new Set(
      videoElements
        .map((element) => {
          return parseInt(callback(element), 10) as SvpAssetId;
        })
        .filter(Boolean),
    ),
  ];
};

export const preloadAssetCache = async (storage: AssetStorage) => {
  const videoElements = getVideoElements();

  await Promise.all(
    [
      {
        vendor: 'vgtv' as const,
        assetIds: getAssetIds(videoElements, (element) => {
          const [, , assetId] = element.pathname.split('/');

          return assetId;
        }),
      },
      {
        vendor: 'brandstudio' as const,
        assetIds: getAssetIds(videoElements, (element) => {
          const [, , , assetId] = element.pathname.split('/');

          return assetId;
        }),
      },
    ].map(async ({ assetIds, vendor }) => {
      if (!assetIds.length) {
        return;
      }

      const assets = await svpApi.asset.searchAssets({
        filter: `assetId::${assetIds.join(',')}`,
        limit: assetIds.length,
        vendor,
      });

      assets.forEach((asset) => {
        storage.setItem(`${vendor}:${asset.id}`, asset);
      });
    }),
  );
};
